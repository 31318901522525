import React from "react";

function AboutPage() {
  return (
    <div className="mt-5">
      <div
        className="bg-neutral-100 p-20 py-44
      text-center"
      >
        <h1 className="font-medium text-sky-900 text-4xl pb-10">
          OUR BUSINESS
        </h1>
        <h1 className="text-2xl pb-8">Innovators. Dreamers.</h1>
        <span className="text-justify p-10">
          iRules-Tech is a leading provider of cutting-edge technologies and
          services, offering scalable solutions for companies of all sizes.
          Founded by a group of friends who started by scribbling their ideas on
          a piece of paper, today we offer smart, innovative services to dozens
          of clients worldwide.
        </span>
        <div className="mt-10">
          <span className="text-justify p-10">
            We built our solutions by closely listening to our potential
            clientele and understanding their expectations with our product. We
            know how to analyze this information and customize our offering to
            changing market needs. Why not join our fast growing customer base?
            Get in touch today to learn more about the iRules-Tech story.
          </span>
        </div>
      </div>
    </div>
  );
}

export default AboutPage;
