import React from "react";
import { Link } from "react-router-dom";
import { GrMenu, GrFormClose } from "react-icons/gr";
import Logo from "../assets/logo.jpeg";

const Header = ({ open, setOpen }) => {
  return (
    <>
      <div
        className="flex sm:hidden w-full
  items-center justify-center p-5"
      >
        <div className="w-screen text-center">
          <Link to="/">
            <img loading="lazy" src={Logo} alt="logo" style={{ height: 50 }} />
          </Link>
        </div>
        <div
          className="text-white text-4xl cursor-pointer"
          onClick={() => setOpen(!open)}
        >
          {open ? <GrFormClose /> : <GrMenu />}
        </div>
      </div>
      <div className="hidden sm:flex h-28 justify-between items-center w-full">
        <Link to="/">
          <img loading="lazy" src={Logo} alt="logo" />
        </Link>

        <div>
          <ul
            className="flex flex-grow text-black 
space-x-3 md:space-x-6 p-5 text-sm lg:text-lg font-semibold"
          >
            <li className="cursor-pointer hover:text-gray-400">
              <Link
                to="/"
                // onClick={() =>
                //   setInterval(() => {
                //     window.location.reload();
                //   }, 100)
                // }
              >
                <span className="font-semibold text-base">Home</span>
              </Link>
            </li>
            <li className="cursor-pointer hover:text-gray-400">
              <Link
                to="/about"
                // onClick={() =>
                //   setInterval(() => {
                //     window.location.reload();
                //   }, 100)
                // }
              >
                <span className="font-semibold text-base">About</span>
              </Link>
            </li>
            <li className="cursor-pointer hover:text-gray-400">
              <Link
                to="/service"
                // onClick={() =>
                //   setInterval(() => {
                //     window.location.reload();
                //   }, 100)
                // }
              >
                <span className="font-semibold text-base">Services</span>
              </Link>
            </li>
            <li className="cursor-pointer hover:text-gray-400">
              <Link
                to="/contact"
                // onClick={() =>
                //   setInterval(() => {
                //     window.location.reload();
                //   }, 100)
                // }
              >
                <span className="font-semibold text-base">Contact</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Header;
