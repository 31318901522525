import React from "react";
import casem from "../assets/case.jpg";
import robotics from "../assets/robotics.jpg";
import crm from "../assets/crm.jpg";
import Logo from "../assets/side.jpg";
import Logo2 from "../assets/side2.jpg";
import Logo3 from "../assets/side3.jpg";
import form from "../assets/form.jpg";

function MainPage() {
  return (
    <div>
      <div className="bg-mainBg bg-center bg-fixed">
        <div
          className="bg-gray-400 bg-opacity-30
         flex flex-col items-center text-center py-72"
        >
          <h1
            className="text-4xl link font-mediums 
        text-white font-bold sm:text-5xl  lg:text-6xl mb-2"
          >
            LETS BUILD BPM SOLUTIONS
          </h1>
        </div>
      </div>
      <div
        className="flex flex-col md:flex-row justify-between w-full my-20 
        md:space-x-7"
      >
        <div
          className="bg-sky-900 p-20 md:w-1/2
           justify-center flex flex-col
           text-white text-center"
        >
          <h1 className="font-medium text-5xl py-5">WHO WE ARE</h1>
          <span className="text-justify">
            iRules Technolgies is a leading provider of cutting-edge
            technologies and services, offering scalable solutions for
            businesses of all sizes. Founded by a group of friends who started
            by scribbling their ideas on a piece of paper, today we offer smart,
            innovative services to dozens of clients worldwide. Why not join our
            fast growing client base? Get in touch today to learn more.
          </span>
        </div>
        <div className="md:w-1/2">
          <img loading="lazy" src={Logo} alt="logo" />
        </div>
      </div>

      <div
        className="flex flex-col md:flex-row justify-between w-full my-20 
        md:space-x-7"
      >
        <div
          className="bg-neutral-100 p-20 md:w-1/2
           justify-center flex flex-col
          text-center"
        >
          <h1 className="font-medium text-sky-900 text-5xl py-5">WHAT WE DO</h1>
          <h1 className="text-2xl pb-5">Easy. Fast. Secure.</h1>
          <span className="text-justify">
            At iRules Technologies, we believe that our solutions will soon
            become one of the biggest segments in the industry. We’ve only just
            started, but we already know that every product we build requires
            hard-earned skills, dedication and a daring attitude. Continue
            reading and learn all there is to know about the smart tech behind
            our successful Information Technology Company.
          </span>
        </div>
        <div className="md:w-1/2">
          <img loading="lazy" src={Logo2} alt="logo" />
        </div>
      </div>

      <div className="flex flex-col md:flex-row justify-between w-full my-20">
        <div className="md:w-1/2">
          <img loading="lazy" src={Logo3} alt="logo" />
        </div>
        <div
          className="bg-neutral-100 p-20 md:w-1/2
           justify-center flex flex-col
          text-center"
        >
          <h1 className="text-2xl pb-5">
            "The secret of getting ahead is getting started"
          </h1>
          <span className="text-justify">Mark Twain</span>
        </div>
      </div>

      <div className="my-20">
        <div className="flex flex-col md:flex-row justify-between p-10 text-white bg-sky-900 w-full">
          <div className="md:w-1/2">
            <h1 className="text-4xl pb-5 text-center">GET IN TOUCH</h1>
          </div>
          <div className="md:w-1/2 justify-center flex flex-col text-center">
            <form
              className="contact-form"
              // onSubmit={sendEmail}
            >
              <div className="flex flex-col space-y-3 text-white">
                <div className="space-y-1">
                  <h1 className="text-lg text-left">Name</h1>
                  <input
                    //value={name}
                    // onChange={(event) => setname(event.target.value)}
                    className="
                    focus:outline-none w-full text-white
                    focus:shadow-md
                    focus-within:border-2 py-3 bg-transparent
                     border-white border-b-2"
                    placeholder="Enter your name"
                    name="name"
                  />
                </div>
                <div className="space-y-1">
                  <h1 className="text-lg text-left">Email</h1>
                  <input
                    //value={email}
                    // onChange={(event) => setemail(event.target.value)}
                    className="
                    focus:outline-none w-full text-white
                    focus:shadow-md
                    focus-within:border-2 py-3 bg-transparent
                     border-white border-b-2"
                    placeholder="Enter your email"
                    name="reply_to"
                  />
                </div>
                <div className="space-y-1">
                  <h1 className="text-lg text-left">Subject</h1>
                  <input
                    //value={project}
                    // onChange={(event) => setproject(event.target.value)}
                    className="
                    focus:outline-none w-full text-white
                    focus:shadow-md
                    focus-within:border-2 py-3 bg-transparent
                     border-white border-b-2"
                    placeholder="Type the Subject"
                    name="subject"
                  />
                </div>
                <div className="space-y-1">
                  <h1 className="text-lg text-left">Message</h1>
                  <textarea
                    //value={message}
                    // onChange={(event) => setmessage(event.target.value)}
                    className="
                    focus:outline-none w-full text-white
                    focus:shadow-md
                    focus-within:border-2 pb-5 bg-transparent
                     border-white border-b-2"
                    placeholder="Type your message here"
                    name="message"
                  />
                </div>
              </div>
              <div>
                <input
                  className="focus:outline-none flex p-2 bg-white m-1 mt-10 
                  hover:bg-slate-400  hover:text-white text-black
                   items-center transition duration-700 ease-in-out
                   justify-center text-xl cursor-pointer w-full 
                   text-center"
                  type="submit"
                  value="Submit"
                />
              </div>
            </form>
          </div>
        </div>
        <img loading="lazy" src={form} alt="logo" />
      </div>

      <div
        className="my-20 flex flex-col md:flex-row justify-between 
        md:space-x-4 space-y-5 md:space-y-0 p-10"
      >
        <div className="border-2 border-black">
          <img
            loading="lazy"
            src={casem}
            alt="Case Management"
            className="img"
          />
          <h1 className="p-5 border-b-2 mx-5 border-black text-2xl">
            Case Management
          </h1>
          <div className="p-8"></div>
        </div>
        <div className="border-2 border-black">
          <img loading="lazy" src={robotics} alt="Robotics" className="img" />
          <h1 className="p-5 border-b-2 mx-5 border-black text-2xl">
            Robotics
          </h1>
          <div className="p-8"></div>
        </div>
        <div className="border-2 border-black">
          <img
            loading="lazy"
            src={crm}
            alt="Customer Relationship Management"
            className="img"
          />
          <h1 className="p-5 border-b-2 mx-5 border-black text-2xl">
            Customer Relationship Management
          </h1>
          <div className="p-8"></div>
        </div>
      </div>
    </div>
  );
}

export default MainPage;
