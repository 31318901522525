import React from "react";
import { Link } from "react-router-dom";

function MobileHeader({ open, setOpen }) {
  return (
    <ul
      className="flex justify-center flex-col items-center text-2xl
    my-10 space-y-5"
    >
      <li className="cursor-pointer hover:text-gray-400">
        <Link to="/" onClick={() => setOpen(!open)}>
          <span className="font-semibold">Home</span>
        </Link>
      </li>
      <li className="cursor-pointer hover:text-gray-400">
        <Link to="/about" onClick={() => setOpen(!open)}>
          <span className="font-semibold">About</span>
        </Link>
      </li>
      <li className="cursor-pointer hover:text-gray-400">
        <Link to="/service" onClick={() => setOpen(!open)}>
          <span className="font-semibold">Services</span>
        </Link>
      </li>
      <li className="cursor-pointer hover:text-gray-400">
        <Link to="/contact" onClick={() => setOpen(!open)}>
          <span className="font-semibold">Contact</span>
        </Link>
      </li>
    </ul>
  );
}

export default MobileHeader;
