import React, { useState } from "react";
import "./App.css";
import "./index.css";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import MainPage from "./page/MainPage";
import AboutPage from "./page/AboutPage";
import ContactPage from "./page/ContactPage";
import ServicePage from "./page/ServicePage";
import Header from "./component/Header";
import Footer from "./component/Footer";
import MobileHeader from "./component/MobileHeader";

//Window Tab Setting
if (window.location.href.includes("about")) {
  document.title = "About | iRules-Tech";
} else if (window.location.href.includes("service")) {
  document.title = "Service | iRules-Tech";
} else if (window.location.href.includes("contact")) {
  document.title = "Contact | iRules-Tech";
} else {
  document.title = "Home | iRules-Tech";
}

function App() {
  const [open, setOpen] = useState(false);
  return (
    <BrowserRouter>
      <div className="md:px-20 select-none">
        <Header open={open} setOpen={setOpen} />
        {open && <MobileHeader open={open} setOpen={setOpen} />}
        {!open && (
          <Switch>
            <Route path="/" exact component={MainPage} />
            <Route path="/about" component={AboutPage} />
            <Route path="/service" component={ServicePage} />
            <Route path="/contact" component={ContactPage} />
          </Switch>
        )}
        {!open && <Footer />}
      </div>
    </BrowserRouter>
  );
}

export default App;
