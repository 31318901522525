import React from "react";

function ContactPage() {
  return (
    <div className="mt-10 mb-20">
      <div className="flex flex-col p-10 py-20 justify-center text-black bg-neutral-100 w-full">
        <div className="">
          <h1 className="text-4xl pb-5 font-medium text-center text-sky-900">
            GET IN TOUCH
          </h1>
        </div>
        <div className="">
          <form
            className="contact-form"
            // onSubmit={sendEmail}
          >
            <div className="flex flex-col space-y-3 text-black">
              <div className="space-y-1">
                <h1 className="text-lg text-left">Name</h1>
                <input
                  //value={name}
                  // onChange={(event) => setname(event.target.value)}
                  className="
              focus:outline-none w-full text-black
              
              focus-within:border-2 py-3 bg-transparent
               border-gray-400 border-b-2"
                  placeholder="Enter your name"
                  name="name"
                />
              </div>
              <div className="space-y-1">
                <h1 className="text-lg text-left">Email</h1>
                <input
                  //value={email}
                  // onChange={(event) => setemail(event.target.value)}
                  className="
              focus:outline-none w-full text-black
              
              focus-within:border-2 py-3 bg-transparent
               border-gray-400 border-b-2"
                  placeholder="Enter your email"
                  name="reply_to"
                />
              </div>
              <div className="space-y-1">
                <h1 className="text-lg text-left">Subject</h1>
                <input
                  //value={project}
                  // onChange={(event) => setproject(event.target.value)}
                  className="
              focus:outline-none w-full text-black
              
              focus-within:border-2 py-3 bg-transparent
               border-gray-400 border-b-2"
                  placeholder="Type the Subject"
                  name="subject"
                />
              </div>
              <div className="space-y-1">
                <h1 className="text-lg text-left">Message</h1>
                <textarea
                  //value={message}
                  // onChange={(event) => setmessage(event.target.value)}
                  className="
              focus:outline-none w-full text-black
              
              focus-within:border-2 pb-5 bg-transparent
               border-gray-400 border-b-2"
                  placeholder="Type your message here"
                  name="message"
                />
              </div>
            </div>
            <div>
              <input
                className="focus:outline-none flex p-2 bg-black m-1 mt-10 
            hover:bg-slate-400  hover:text-black text-white
             items-center transition duration-700 ease-in-out
             justify-center text-xl cursor-pointer w-full 
             text-center"
                type="submit"
                value="Submit"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ContactPage;
