import React from "react";
import casem from "../assets/case.jpg";
import robotics from "../assets/robotics.jpg";
import crm from "../assets/crm.jpg";

function ServicePage() {
  return (
    <div>
      <h1 className="font-medium text-4xl text-center mt-10">Our service</h1>
      <div
        className="my-20 flex flex-col items-center md:flex-row md:justify-center 
    md:space-x-4 space-y-5 md:space-y-0 md:items-stretch"
      >
        <div className="border-2 border-black w-72">
          <img
            loading="lazy"
            src={casem}
            alt="Case Management"
            className="img h-52 w-72"
          />
          <h1 className="p-5 border-b-2 mx-5 border-black text-xl">
            Case Management
          </h1>
          <div className="p-3"></div>
          <input
            className="focus:outline-none flex p-3 bg-sky-900 my-10 mt-16 
                  hover:bg-slate-400  hover:text-black text-white
                   items-center transition duration-700 ease-in-out
                   justify-center cursor-pointer mx-10 
                   text-center"
            type="submit"
            value="Book Now"
          />
        </div>
        <div className="border-2 border-black w-72">
          <img
            loading="lazy"
            src={robotics}
            alt="Robotics"
            className="img h-52 w-72"
          />
          <h1 className="p-5 border-b-2 mx-5 border-black text-xl">Robotics</h1>
          <div className="p-3"></div>
          <input
            className="focus:outline-none flex p-3 bg-sky-900 my-10 mt-16
                  hover:bg-slate-400  hover:text-black text-white
                   items-center transition duration-700 ease-in-out
                   justify-center cursor-pointer mx-10 
                   text-center"
            type="submit"
            value="Book Now"
          />
        </div>
        <div className="border-2 border-black w-72">
          <img
            loading="lazy"
            src={crm}
            alt="Customer Relationship Management"
            className="img h-52 w-72"
          />
          <h1 className="p-5 border-b-2 mx-5 border-black text-xl">
            Customer Relationship Management
          </h1>
          <div className="p-3"></div>
          <input
            className="focus:outline-none flex p-3 bg-sky-900 my-10 
                  hover:bg-slate-400  hover:text-black text-white
                   items-center transition duration-700 ease-in-out
                   justify-center cursor-pointer mx-10 
                   text-center"
            type="submit"
            value="Book Now"
          />
        </div>
      </div>
    </div>
  );
}

export default ServicePage;
