import React from "react";
import { GrFacebookOption, GrTwitter, GrLinkedinOption } from "react-icons/gr";

function Footer() {
  return (
    <div className="mt-20 mb-10">
      <div className="flex justify-center space-x-5">
        <GrFacebookOption />
        <GrTwitter />
        <GrLinkedinOption />
      </div>
      <h1 className="text-center mt-5">©2022 by iRules-Tech.</h1>
    </div>
  );
}

export default Footer;
